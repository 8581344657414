<template>
  <div class="dialogueModel speakingModel" :class="dialogueData.type">
    <div class="stemWrap">
      <img class="personIcon" :src="require(`@/assets/images/${dialogueData.type}.png`)">
      <div class="sentWrap" @click="playAudioHandle">
        <div class="sent">
          <div class="audio" ref="dialogueAudio"></div>
          <template v-for="(word, i) in words">
            <div v-if="word.ty === '1'" class="punc" :class="{highlight: word.highlight}" :key="i">{{word.wd}}</div>
            <div v-else class="word" :class="{highlight: word.highlight, hollow: word.hollow}" :key="i">{{word.wd}}</div>
          </template>
        </div>
        <div class="trans" v-show="showTrans">{{dialogueData['tr']}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import dialogueMixins from '@/mixins/coursesDialogueMixins'

  export default {
    data() {
      return {
        correct: true,
        // 该值为对应难度下的挖空数，判断标准x为句子除标点外的单词数
        // 如果x ≤ 2，则挖空数为 0，1，1
        // 如果x = 3，则挖空数为 0，1，2
        // 如果x ≥ 3，则挖空数为 0，2，3
        difficultyHollow: {
          'easy': [0, 0, 0],
          'medium': [1, 1, 2],
          'hard': [1, 2, 3]
        }
      }
    },
    mixins: [ dialogueMixins ],
    props: ['showTrans', 'difficulty'],
    watch: {
      difficulty(val) {
        let hollowNum = 0
        if (this.wordsNum <= 2) {
          hollowNum = this.difficultyHollow[val][0]
        } else if (this.wordsNum === 3) {
          hollowNum = this.difficultyHollow[val][1]
        } else {
          hollowNum = this.difficultyHollow[val][2]
        }
        this.hollowHandle(hollowNum)
      }
    },
    mounted() {
      this.playAudioHandle()
    },
    methods: {
      hollowHandle(hollowNum) {
        for (let word of this.words) {
          this.$set(word, 'hollow', false)
        }
        
        let hollowIndexArr = []
        while (hollowIndexArr.length < hollowNum) {
          let index = Math.floor(Math.random() * this.words.length)
          let word = this.words[index]
          if (word.ty !== '1' && !hollowIndexArr.includes(index)) {
            hollowIndexArr.push(index)
          }
        }

        for (let index of hollowIndexArr) {
          let word = this.words[index]
          this.$set(word, 'hollow', true)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hollow {
    color: transparent;
  }
</style>